import React, {Component} from 'react'
import {Layout} from '../layouts'
import {
    Slider,
    Features,
    RequestRates,
    About
} from '../containers'

class Home extends Component {
    render() {
        return (
            <Layout location={this.props.location} isHome full>
                <Slider/>
                <Features/>
                <About/>
                <RequestRates/>
            </Layout>
        )
    }
}

export default Home
